<template>
  <div class="md-layout md-gutter">
        <!-- <div class="md-layout-item md-size-75">
           <TextEditor
                class="h-64"
                :label="false"
            />
        </div> -->
      
      <div class="md-layout-item md-size-100">
          <MCQAnswer 
            label-outline block 
            :items="items" 
            v-model="form.quiz_options" />
      </div>

  </div>
</template>

<script>
//import TextEditor from "@/components/atom/form/TextEditor";
import MCQAnswer from "@/components/molecule/question/MultipleChoiseAnswer";
//import RadioButtonComponent from "@/components/atom/form/RadioButtonComponent";
export default {
    components: {
       // TextEditor,
        //RadioButtonComponent,
        MCQAnswer
    },
    data() {
        return {
            items: [
                {id: 1, label: 'Answer Sample'},
                {id: 2, label: 'Answer Sample'},
                {id: 3, label: 'Answer Sample'},
                {id: 4, label: 'Answer Sample'},
            ],
            form: {
                quiz_options: [],
            },
        }
    }
}
</script>
