<template>
  <div class="md-layout md-gutter">
      <div class="md-layout-item md-size-100">
           <TextEditor
                class="h-64"
                :label="false"
                v-model="form.quiz_options" 
            />
      </div>
  </div>
</template>

<script>
import TextEditor from "@/components/atom/form/TextEditor";
export default {
    components: {
        TextEditor
    },
    data() {
        return {
            form: {
                quiz_options: [],
            },
        }
    }
}
</script>
