<template>
    <form @submit.prevent="onSubmit(true)">
        <div class="md-layout mb-10">
            <div class="md-layout-item">
                <h2 class="text-uppercase text-2xl">Create new quiz</h2>
            </div>
            <div class="md-layout-item flex justify-end">
                <md-button @click="$router.go(-1)" class="rounded">
                <md-icon>list</md-icon>  Back Quiz List
                </md-button>
            </div>
        </div>
      <div class="md-layout md-gutter flex align-center mt-6">
          <div class="md-layout-item md-size-17">
              <p class="text-uppercase text-base font-semibold">Quiz Question Title</p>
          </div>
          <div class="md-layout-item md-size-50">
            <InputFieldComponent
               v-model.trim="form.index"
            />
          </div>
          <!-- <div class="md-layout-item flex justify-end">
              <div class="outline-gray-400 inline-block p-2 rounded">
                  <md-icon>turned_in_not</md-icon>
              </div>
          </div> -->
      </div>
        <div class="md-layout md-gutter flex align-center mt-6">
            <div class="md-layout-item md-size-17">
                <p class="text-uppercase text-base font-semibold">Question Type</p>
            </div>
            <div class="md-layout-item md-size-25">
                <SelectComponent
                    :items="questionTypes"
                    class="w-full"
                    :placeholder="false"
                    return-type="index"
                    @getIndex="getQuestionIndex"
                    v-model.trim="form.questionTypes"
                />
            </div>
            <div class="md-layout-item md-size-15">
                <p class="text-uppercase text-base font-semibold">Answer Type</p>
            </div>
            <div class="md-layout-item md-size-25">
                <SelectComponent
                    :items="answerTypes"
                    class="w-full"
                    :placeholder="false"
                    return-type="index"
                    @getIndex="getAnswerIndex"
                    v-model.trim="form.answer_type"
                />
            </div>
        </div>
        <div class="md-layout md-gutter flex align-center mt-6">
          <div class="md-layout-item md-size-17">
              <p class="text-uppercase text-base font-semibold">TAGS</p>
          </div>
          <div class="md-layout-item md-size-65">
            <TagInput v-model.trim="form.tags"/>
          </div>
        </div>
        <div class="md-layout md-gutter flex align-center mt-6">
          <div class="md-layout-item md-size-100">
            <TextEditor 
              label="Quiz Prompt"
              v-model.trim="form.transcript"
            />
          </div>
        </div>
      <div class="my-6" style="border-bottom:1px solid #cbd5e0">
          <h2 class="text-uppercase text-victoria pb-3">Question</h2>
      </div>
      <components ref="child" v-model="form.title" :is="component"></components>
      <div class="my-6" style="border-bottom:1px solid #cbd5e0">
          <h2 class="text-uppercase text-victoria pb-3">Answer</h2>
      </div>
      <components ref="child" :is="answerComponent"></components>
      <div class="flex justify-end mt-6">
          <md-button class="bg-default rounded">Save Draft </md-button>
          <Button label="Publish" type="submit" class="mr-0 bg-victoria text-white rounded">Publish</Button>
      </div>
    </form>
</template>

<script>
import {
    Button,
    InputFieldComponent,
    TextAreaComponent,
    SelectComponent
} from "@/components";
import Questions from "@/components/molecule/pte-quiz";
import TextEditor from "@/components/atom/form/TextEditor";
import TagInput from "@/components/atom/form/TagInput";
import data from "@/data/pte-quiz/data";
//import { required } from "vuelidate/lib/validators";
import { mapMutations, mapGetters, mapActions } from "vuex";
export default {
    components: {
        Button,
        InputFieldComponent,
        SelectComponent,
        TextAreaComponent,
        TextEditor,
        TagInput,
        ...Questions
    },
    data() {
        return {
            questionTypes: data.questionTypes,
            answerTypes: data.answerTypes,
            component: 'AudioAndText',
            quiz_id: '',
            answerComponent: 'MCQChooseMultipleAnswer',
            answerType: "MCQ-CHOOSE-MULTIPLE-ANSWER",
            form: {
                active: false,
                quiz_type_id: "",
                answer_type: "",
                index: "",
                image_resource_path: null,
                video_resource_path: null,
                transcript: "",
                tags: "",
                quiz_options: []
            },
        }
    },
    computed: {
    ...mapGetters({
      getQuiz: "question/getQuiz",
      getSelectedQuiz: "question/getSelectedQuiz"
    }),
   
  },
    methods: {
        ...mapMutations({
            dialog: "setShowDialog",
            setShowSnackbar: "setShowSnackbar",
            setSelectedQuiz: "question/setSelectedQuiz",
            setQuiz: "question/setQuiz",
        }),
        ...mapActions({
           submitQuiz: 'question/actSubmitQuiz',
           actGetQuiz: "question/actGetQuiz",
        }),
        getQuestionIndex(index) {
           this.component = this.questionTypes[index].component;
           console.log(this.component);
           this.quiz_id = this.getQuiz[index].id

        //this.setSelectedQuiz(this.getQuiz[index]);
        //this.actQuizTypeDetails(this.getQuiz[index].id)
        //    .then( item => {
            //this.assignUtilsForQuestion(item);
           // console.log('item',item)
            //this.is_sample_answer = item.is_sample_answer
         //   });
        },
        getAnswerIndex(index) {
           this.answerComponent = this.answerTypes[index].component;
           this.answerType = this.answerTypes[index].type;
           console.log(this.answerType,this.getSelectedQuiz,this.getQuiz)
        },
        onSubmit(active) {
           // this.$v.$touch();
            // this.$refs.child.$v.$touch();
            // if(this.$v.$invalid || this.$refs.child.$v.$invalid) {
            //     return;
            // }
            console.log('q type',this.quiz_id)
            this.form.quiz_type_id = this.quiz_id;
            this.form.active = active;
            this.form.answer_type = this.answerType;
            // if(typeof this.form.title != 'string') {
            //     this.form.title = '';
            // }
            this.form = {...this.form, ...this.$refs.child.form}
            this[(active == false) ? 'draftLoading' : 'loading'] = true;
            this.submitQuiz(this.form)
            .then(response => {
                this.setShowSnackbar(response.message);
                if(!this.createAnother) {
                this.$router.go(-1)
                }
            }).catch(error => {
                this.setShowSnackbar(error.response.data.message);
            })
            .finally(() => this[(active == false) ? 'draftLoading' : 'loading'] = false);        
        },
    },
    validations: {
        form: {
            //index: {required},
        },
    },
    mounted() {
        this.component = this.questionTypes[0].component;
        this.answerComponent = this.answerTypes[0].component;
         this.actGetQuiz(`?section=${this.component}`)
        .then(( type ) => {
             this.actQuizTypeDetails(type.id)
                 .then( item => {
                console.log('ssssas',item)
                });
         });
    }
}
</script>