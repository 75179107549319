<template>
  <div>
    <h2 class="text-uppercase text-victoria mb-3">{{ label }}</h2>
    <ul >
      <li v-for="(item, index) in items" :key="index" class="my-3 flex align-center">
        <md-checkbox v-model="array" :value="index"> </md-checkbox>
        <InputFieldComponent v-model="item.title" @HandleTargetValue="HandleTargetValue(index)"  class="w-74" />
      </li>
    </ul>
    <b id="scrollTop" class="text-danger" v-if="message">{{ message }}</b>
  </div>
</template>

<script>
import { InputFieldComponent } from '@/components'
export default {
  components: {
    InputFieldComponent
  },
  data: () => ({
    array: [],
    is_correct_answer: [],
    form: {
        full_marks: null
    },
  }),
  props: {
    label: {
      type: String
    },
    labelOutline: {
      type: Boolean,
      default: () => true
    },
    message: {
      type: String,
      default: () => null
    },
    items: {
      type: Array,
    }
  },
  watch: {
    array(value) {
        this.items.map(item => delete item.drop_down_options);
        //this.case_item.question_options[value].is_correct_answer = true;
        this.items.forEach((item) => {
            item.is_correct_answer = false ;
        });
        for( var i = 0; i < value.length; i++){
            let x = value[i]
            this.items[x].is_correct_answer = true;
           
            
            
        }
        this.$emit('input', this.items); 
        console.log('ss',this.form.full_marks)
    }
  },
  methods:{
    HandleTargetValue(){
      this.$emit('input', this.items);  
    },
  }
};
</script>

<style></style>
