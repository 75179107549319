<template>
  <div class="ap tag-input border border-solid rounded border-gray-400 input-box-shadow">
    <div v-for="(tag, index) in tags" :key="index" class="tag-input__tag">
      {{ tag }}
      <span @click='removeTag(index)'><md-icon>close</md-icon></span>
    </div>
    <input 
        type="text" 
        placeholder="Enter a Tag" 
        class="tag-input__text "
        @keydown.enter='addTag' 
        @keydown.188='addTag' 
        @keydown.delete='removeLastTag'
    />
  </div>
</template>
<script>
  export default {
    data () {
      return {
        tags: []
      }
    },
    methods: {
        addTag (event) {
            event.preventDefault()
            var val = event.target.value.trim()
            if (val.length > 0) {
                this.tags.push(val)
                event.target.value = ''
            }
            this.$emit('input', this.tags)
            //console.log(val,'dd',this.tags)
        },
        removeTag (index) {
           this.tags.splice(index, 1)
        },
        removeLastTag(event) {
            if (event.target.value.length === 0) {
             this.removeTag(this.tags.length - 1)
            }
        }
    }
  }
</script>