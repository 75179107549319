const questionTypes = [
    {
        id: 1,
        name: 'AUDIO + TEXT',
        component: 'AudioAndText'
    },
    {
        id: 2,
        name: 'IMAGE + TEXT',
        component: 'ImageAndText'
    },
    {
        id: 3,
        name: 'VIDEO + TEXT',
        component: 'VideoAndText'
    },
    {
        id: 4,
        name: 'TEXT + TEXT',
        component: 'TextAndText'
    }
];

const answerTypes = [
    {
        id: 1,
        name: 'MCQ- CHOSE MULTIPLE ANSWER',
        component: 'MCQChooseMultipleAnswer',
        type: 'MCQ-CHOOSE-MULTIPLE-ANSWER'
    },
    {
        id: 2,
        name: 'MCQ- CHOSE SINGLE ANSWER',
        component: 'MCQChooseSingleAnswer',
        type: 'MCQ-CHOOSE-SINGLE-ANSWER'
    },
    {
        id: 3,
        name: 'TEXT',
        component: 'TestAnswer',
        type: 'TEXT'
    }
];

export default {
    questionTypes,
    answerTypes
}