<template>
  <div>
    <Card>
      <template slot="outer">
        <PageTopOuter
          icon="campaign"
          label="PTE Quiz"
          :search-box="false"
          :show-dialog="false"
          class="mt-4"
          @on-create="onCreateNew"
        />
      </template>
      <CardBody class="p-3">
        <div class="mb-3 flex justify-between flex-wrap justify-sm-center justify-xs-center">
          <!-- it will be Sort by date -->
          <SelectComponent
            :placeholder="false"
            class="w-64 m-2"
            :items="statuses"
            return-type="index"
          />
          <SearchBox
            v-model="keyword"
            placeholder="Search"
            class="p-2 w-64 m-2"
            />
        </div>
        <md-divider></md-divider>
        <Table :items="questions" hover-action>
          <template slot="row" slot-scope="{ item }" md-selectable="single">
            <md-table-cell md-label="SL" md-numeric>{{ item.sl }}</md-table-cell>
            <md-table-cell md-sort-by="q_id" md-label="Q_ID">{{ item.quiz_id }}</md-table-cell>
            <md-table-cell md-sort-by="index" md-label="Q_TITLE"> {{ item.index }} </md-table-cell>
            <md-table-cell md-sort-by="q_type" md-label="Q.TYPE" style="font-size:20px;font-weight:600;text-align:center">♫+Τ</md-table-cell>
            <md-table-cell md-sort-by="a_type" md-label="A.TYPE" style="font-size:24px;font-weight:600;text-align:center">
            {{ item.answer_type === 'MCQ-CHOOSE-SINGLE-ANSWER' ? '◉' : item.answer_type === 'MCQ-CHOOSE-MULTIPLE-ANSWER' ? '☑': 'Τ' }}
            </md-table-cell>
            <md-table-cell md-sort-by="tag" md-label="TAGS">{{ item.tag }}</md-table-cell>
            <md-table-cell md-sort-by="created_at" md-label="CREATED AT">{{ item.created_at }}</md-table-cell>
            <md-table-cell  md-sort-by="created_by" md-label="CREATED BY" class="text-victoria ">
              <span class="table-cell-link">{{ item.created_by }}</span>
            </md-table-cell>
            <md-table-cell md-sort-by="active" md-label="STATUS" class="text-green">{{ (item.active == 1) ? 'Published' : 'Draft' }}</md-table-cell>
            <md-table-cell>
              <span class="action flex">
                <md-icon class="bg-blue-chill rounded-full p-1 text-white text-base mr-1">visibility</md-icon>
                <md-icon class="bg-victoria rounded-full p-1 text-white text-base mr-1">edit</md-icon>
                <md-icon class="bg-tahiti-gold text-white rounded-full p-1 text-base">delete</md-icon>
              </span>
            </md-table-cell>
          </template>
        </Table>
      </CardBody>
    </Card>
    <div class="flex justify-end my-6" v-if="questions.length > 20">
      <Paginate
       :start="paginate.start"
       :end="paginate.end"
       :total="paginate.total"
       :limit="paginate.limit"
        @on-start="onStart"
        @on-end="onEnd"
      />
    </div>
    <ConfirmDialog 
      @confirm="actDeleteQuestion(questionId)"
      :active.sync="active"
    />
  </div>
</template>

<script>
import {
  Card,
  CardBody,
  SelectComponent,
  ConfirmDialog,
  PageTopOuter,
  Table,
  Paginate
  } from "@/components";
import SearchBox from "@/components/atom/SearchBox";
//import { find } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
//import cms from "@/data/cms"
import { query } from "@/utils/queryString";
export default {
  name: "SpeakingQuestionListView",
  components: {
    Card,
    Paginate,
    CardBody,
    PageTopOuter,
    ConfirmDialog,
    SelectComponent,
    SearchBox,
    Table
  },
  data() {
    return {
      active: false,
      pagination: true,
      paginate: {
        start: 1,
        end: 20,
        total: 100,
        limit: 20
      },
      statuses:[
          {
              "id": 0,
              "name": "ALL"
          },
          {
              "id": 1,
              "name": "Draft"
          },
          {
              "id": 2,
              "name": "Published"
          }
      ],
      // questions:[
      //   {
      //       "id": "01992a58-9916-4571-9f28-f329c86eb6f1",
      //       "sl": '1',
      //       "q_id": "Q018",
      //       "index": "I am title",
      //       "q_type": "♫+Τ",
      //       "a_type": "◉",
      //       "tag": "grammar,english....",
      //       "active": 1,
      //       "created_at": "29 May, 2022 11:06 am",
      //       "created_by": "Kazi Shahin",
      //   },
      //   {
      //       "id": "01992a58-9916-4571-9f28-f329c86eb6fa",
      //       "sl": '2',
      //       "q_id": "Q0188",
      //       "index": "I am title",
      //       "q_type": "Τ+Τ",
      //       "a_type": "☑",
      //       "tag": "english,canada....",
      //       "active": 1,
      //       "created_at": "29 May, 2022 11:06 am",
      //       "created_by": "Md. mehedi",
      //   }
      // ]
    }
  },
   computed: {
    ...mapGetters({
      questions: "question/getQuizWiseQuestion",
      statuses: "cms/getStatuses",
    })
  },
  watch: {
    keyword(oldvalue, newValue) {
        query.set('search', oldvalue)
        // For reseting search result
        if(oldvalue.length < newValue.length && oldvalue.length == 0) {
          this.actQuizWiseQuestion(`?${query.get()}`);
        }
        // For submitting search api after 3 cherecter
        if(oldvalue.length >= 3) {
          this.actQuizWiseQuestion(`?${query.get()}`);
        }
    }
  },
  methods: {
    ...mapActions({
      actGetQuestionTypes: "question/actGetQuestionTypes",
      actQuizWiseQuestion: "question/actQuizWiseQuestion",
      actGetDropdowns: "cms/actGetDropdowns",
      actDeleteQuestion: "question/actDeleteQuestion",
      actQuestionDetails: "question/actQuestionDetails",
      actGetUserInformation: "user/actGetUserInformation",
    }),
    onDetails(questionId) {
      this.$emit('on-click', {
        component: 'QuestionDetails'
      });
      this.actQuestionDetails(questionId);
    },
    onEdit(questionId) {
      this.$router.push({
        name: 'pte-question.edit',
        params: {
          id: questionId
        },
        query: {
          type: 'listening'
        }
      })
    },
    toLower(text){
      return text.toString().toLowerCase()
    },
    getStatus(index) {
      this.selectedValue = this.toLower(this.statuses[index].id);
      if(this.selectedValue == 'all') {
        query.remove('active');
      }else {
        query.set('active', this.selectedValue);
      }
      this.actQuizWiseQuestion(`?${query.get()}`);
    },
    submitSearchKeyword() {
      this.actQuizWiseQuestion(`?${query.get('search', this.keyword)}`);
    },
    deleteQuestion(questionId) {
      this.active = true;
      this.questionId = questionId;
    },
    showCreatedByProfile(createdById) {
      this.$emit('on-click', {
        component: 'CreatedByProfile'
      });
     this.actGetUserInformation(createdById);
    },
    showUsedForList(questionId) {
      this.$emit('on-click', {
        component: 'QuestionUsedForList'
      });
      this.actQuestionDetails(questionId);
    },
    getIconColor(frequency) {
      let frequencyObject = find(this.frequencyStyles, ['id', frequency]);
      return frequencyObject ? frequencyObject.color : ''
    },
    onCreateNew() {
      this.$router.push({
        name: 'pte-quiz.create',
      });
    }
  },
  created() {
   // query.set('type', 'question');
   // query.set('section', 'listening');
    this.actQuizWiseQuestion(``);
    this.actGetDropdowns(`?status`);
  }
};
</script>